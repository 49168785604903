<template>
    <div class="table">
        <table>
            <thead>
                <tr>
                    <td>Название курса</td>
                    <td>Билетов пройдено</td>
                    <td>Общий результат</td>
                    <td>Промежуточная аттестация</td>
                    <td>Итоговая аттестация</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in statistics" :key="index">
                    <td>{{ item.code }}</td>
                    <td>{{ item.tests.passed }} из {{ item.tests.count }} билетов</td>
                    <td>{{ item.percent }} верных ответов</td>
                    <td>{{ isIntermediate(item) }}</td>
                    <td>{{ item.exam ? 'сдал' : 'не сдал' }}</td>
                </tr>

            </tbody>
        </table>
    </div>
</template>

<script>
import store from "@/store/index.js"

export default {
    beforeRouteEnter(to, from, next) {
        store.dispatch("PERSONAL_GET_STATISTICS").then(() => {
            next();
        });
    },

    computed: {
        statistics() {
            return this.$store.state.student.statistics
        },
    },
    methods: {
        isIntermediate(item) {
            if (item.has_intermediate) {
                if (item.intermediate) return'сдал'
                else return 'не сдал'
            } else return '-'
        }
    }
}
</script>
